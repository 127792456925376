import React from "react";
import Landing from "./sections/landing/Landing";
import {CssBaseline} from "@mui/material";
import Mission from "./sections/mission/Mission";
import WhatDoWeDo from "./sections/whatDoWeDo/WhatDoWeDo";
import Footer from "../../components/Footer/Footer";
import Pricing from "./sections/Pricing/Pricing";

function Home() {
    return (
        <>
            <CssBaseline/>
            <Landing/>
            <Mission/>
            <WhatDoWeDo/>
            <Pricing/>
            <Footer/>
        </>
    )
}

export default Home;
