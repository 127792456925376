import {useTheme} from "@mui/material";

function PricingStyles() {
    const theme = useTheme();

    return {
        root: {
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(5)
            },
            paddingTop: "2rem",
            paddingBottom: "2rem",
        },
    }
}

export default PricingStyles;
