import {useTheme} from "@mui/material";

function WhatDoWeDoStyles() {
    const theme = useTheme();

    return {
        root: {
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(5)
            },
            [theme.breakpoints.up("md")]: {
                paddingTop: theme.spacing(5)
            }
        },
        titleKeys: {
            fontWeight: "bold"
        },
        roundedPaper: {
            borderRadius: "40px",
            height: "31rem",
            width: "15rem",
            backgroundPosition: "center",
            backgroundSize: "cover"
        },
        deviceScreenshot: {},
        flexboxCenter: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
                marginBottom: theme.spacing(10)
            }
        },
        screenshotBox: {
            paddingTop: theme.spacing(12)
        },
        stepNumber: {
            paddingTop: theme.spacing(6),
            [theme.breakpoints.down("sm")]: {
                paddingTop: theme.spacing(2)
            }
        }
    }
}

export default WhatDoWeDoStyles;
