import React from "react";
import LightTheme from "../../assets/themes/LightTheme";
import DarkTheme from "../../assets/themes/DarkTheme";
import {ThemeProvider, useMediaQuery} from "@mui/material";

function Theme(props) {


    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    let theme = LightTheme;
    if (prefersDarkMode) {
        theme = DarkTheme;
    }

    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )

}

export default Theme;
