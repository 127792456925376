import React from "react";
import {Container, Grid, Typography} from "@mui/material";
import {ConfirmationNumber, Groups, MailOutline, SelfImprovement} from "@mui/icons-material";
import MissionStyles from "./MissionStyles";
import MissionItem from "./MissionItem/MissionItem";

function Mission() {
    const missionStyles = MissionStyles();

    return (
        <Container maxWidth={"xl"} sx={missionStyles.root}
                   style={{paddingTop: "4rem", paddingBottom: "2rem", display: "flex", alignItems: "center"}}>
            <div style={{width: "100%"}}>
                <Typography variant={"h1"} component={"h2"}>
                    Anmelden neu gedacht
                </Typography>
                <Typography variant={"h6"} component={"p"} gutterBottom>
                    Macht, was ihr am besten könnt: Trainieren.<br/>
                    Anmeldungen stellen jede Saison eine neue Hürde dar. Mit Enroll kannst du den Fokus wieder auf das
                    Wichtige legen.
                </Typography>
                <div style={{paddingBottom: "3rem"}}/>
                <Grid container spacing={5} justifyItems={"center"} justifyContent={"center"}>
                    <Grid item xs={12} md={3} sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <MissionItem title={"Anmeldungen"} icon={<ConfirmationNumber/>}
                                     text={"Deine Mitglieder melden sich bequem bei dir an"}/>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <MissionItem title={"Planen"} icon={<Groups/>}
                                     text={"Wähle einen automatischen Vorschlag oder stelle deine Trainingsgruppen selbst zusammen"}/>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <MissionItem title={"Informieren"} icon={<MailOutline/>}
                                     text={"Informiere deine Mitglieder über Neuigkeiten"}/>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <MissionItem title={"Stressfrei"} icon={<SelfImprovement/>}
                                     text={"Kein Papierkram mehr. Falls du Fragen haben solltest, helfen wir dir gerne weiter"}/>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}

export default Mission;
