import {useTheme} from "@mui/material";

function MissionStyles() {
    const theme = useTheme();

    return {
        root: {
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(5)
            },
            [theme.breakpoints.up("md")]: {
                paddingTop: theme.spacing(5)
            }
        }
    }
}

export default MissionStyles;
