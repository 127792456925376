import {createTheme, responsiveFontSizes} from "@mui/material";


const DarkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#1976d2",
            light: "#63a4ff",
            dark: "#004ba0",
            contrastText: "#ffffff"

        },
        secondary: {
            main: "#e57373",
            light: "#ffa4a2",
            dark: "#af4448",
            contrastText: "#000000"
        },
        text: {
            primary: "#FBF7F5",
            secondary: "#9e9e9e"
        },
        background: {
            default: "#121212",
            paper: "#121212",
        },
        divider: "rgba(255, 255, 255, 0.12)",

    },
    typography: {
        fontFamily: [
            'Quicksand',
            "Arial"
        ].join(','),
    }
})
export default responsiveFontSizes(DarkTheme);
