import React, {useState} from "react";
import {Box, Button, Container, Grid, Paper, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import WhatDoWeDoStyles from "./WhatDoWeDoStyles";
import ClubOverview from "../../../../assets/img/desktopScreenshots/ClubSeite.webp";
import CreateSeason from "../../../../assets/img/desktopScreenshots/SaisonerstellungUebersicht.webp";
import ExportPlayers from "../../../../assets/img/desktopScreenshots/Export.webp";

function WhatDoWeDo() {
    const missionStyles = WhatDoWeDoStyles();

    const [activeStep, setActiveStep] = useState(0);

    const steps = [
        {
            image: CreateSeason,
            label: "Erstellen",
            text: "Erstelle eine neue Saison mit individuellen Kursen, Zeiten & Preisen."
        },
        {
            image: ClubOverview,
            label: "Anmeldung",
            text: "Deine Mitglieder melden sich bequem online an. Dabei können sich deine Mitglieder auf deiner Clubseite einen Überblick verschaffen."
        },
        {
            image: ExportPlayers,
            label: "Planen",
            text: "Lade deine Daten & erstelle deinen Trainingsplan und teile ihn mit deinen Angemeldeten"
        },
    ]

    function goToNext() {
        let stepIndex = activeStep;
        stepIndex++
        if ((stepIndex) >= steps.length) {
            stepIndex = 0;
        }
        setActiveStep(stepIndex);
    }

    function goToPrev() {
        let stepIndex = activeStep;
        stepIndex--
        if ((stepIndex) < 0) {
            stepIndex = steps.length - 1;
        }
        setActiveStep(stepIndex);
    }

    return (
        <Container maxWidth={"xl"} sx={missionStyles.root}
                   style={{paddingTop: "4rem", paddingBottom: "2rem", display: "flex", alignItems: "center"}}>
            <div style={{width: "100%"}}>
                <Typography variant={"h1"} component={"h2"}>
                    Wie funktioniert das?
                </Typography>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <div style={{paddingBottom: "3rem"}}/>
                    <Grid container>
                        <Grid item xs={12} md={8} sx={{display: "flex", justifyContent: "center"}}>
                            <Paper elevation={3} sx={{borderRadius: "10px", width: "90%"}}>
                                <img
                                    src={steps[activeStep].image} style={{width: "100%", borderRadius: "10px"}}
                                    alt={"Bild des Schrittes " + steps[activeStep].label}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{maxWidth: 400}}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={step.label} completed={false}>
                                            <StepLabel>
                                                {step.label}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>{step.text}</Typography>
                                                <Box sx={{mb: 2}}>
                                                    <div>
                                                        <Button
                                                            disabled={index === 0}
                                                            onClick={goToPrev}
                                                            sx={{mt: 1, mr: 1}}
                                                        >
                                                            Zurück
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            onClick={goToNext}
                                                            sx={{mt: 1, mr: 1}}
                                                        >
                                                            {index === steps.length - 1 ? 'Von Anfang' : 'Weiter'}
                                                        </Button>
                                                    </div>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        /*
                    <div style={{paddingBottom: "3rem"}}/>

                        <div>

                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label} completed={false}>
                                    <StepLabel>{label.text}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                         */
                    }

                </div>
                <div style={{paddingBottom: "3rem"}}/>

            </div>
        </Container>
    )
}

export default WhatDoWeDo;
