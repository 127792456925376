import {Avatar, Card, Grid, Link, Tooltip, Typography} from "@mui/material";
import {CalendarMonthOutlined, CallOutlined, MailOutline} from "@mui/icons-material";
import React from "react";

function MemberCardSlim(props) {

    const {name, mail, imageTitle, telephone, image, teamFunction, plannerLink} = props;

    return (
        <Card sx={{p: 2}} elevation={5}>
            <Grid container alignItems={"center"}>
                <Grid item xs={3}>
                    <Avatar alt={imageTitle} sx={{width: 70, height: 70}}
                            src={image}/>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant={"h4"}>{name}</Typography>
                    <Typography color={"text.secondary"}>{teamFunction}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Link href={"mailto:" + mail}>
                        <Tooltip title={mail}>
                            <MailOutline color={"secondary"}/>
                        </Tooltip>
                    </Link>
                </Grid>
                {telephone ? (
                    <Grid item xs={1}>
                        <Link href={"tel:" + telephone}>
                            <Tooltip title={telephone}>
                                <CallOutlined color={"secondary"}/>
                            </Tooltip>
                        </Link>
                    </Grid>
                ) : null}
                {plannerLink ? (
                    <Grid item xs={1}>
                        <Link href={plannerLink} rel="noopener" target="_blank">
                            <CalendarMonthOutlined color={"secondary"}/>
                        </Link>
                    </Grid>
                ) : null}
            </Grid>
        </Card>
    )
}

export default MemberCardSlim;
