import React from "react";
import {Paper, Typography} from "@mui/material";
import PropTypes from "prop-types";

function MissionItem(props) {

    return (
        <>
            <Paper sx={{
                backgroundColor: "secondary.main",
                width: "10rem",
                height: "10rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px",
                marginBottom: "1rem"
            }}>
                {React.cloneElement(props.icon, {sx: {color: "white"}, style: {width: "65%", height: "auto"}})}
            </Paper>
            <Typography variant={"h4"} align={"center"}>{props.title}</Typography>
            <Typography variant={"body1"} align={"center"}>{props.text}</Typography>
        </>
    )

}

MissionItem.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    text: PropTypes.string
}
export default MissionItem;