import {Box, Card, CardContent, CardHeader, Container, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import PricingStyles from "./PricingStyles";

function Pricing() {

    const [paymentType, setPaymentType] = useState(0);
    const pricingStyles = PricingStyles();

    useEffect(() => {
        setPaymentType(0)
    }, []);

    const tiers = [
        {
            title: 'Starter',
            highlighted: false,
            price: {monthly: '50', yearly: "550"},
            description: [
                'Vereinsseite',
                'Anmeldungen',
                'Informationsservice',
                'Support',
            ],
            buttonText: 'Sign up for free',
            buttonVariant: 'outlined',
        },
        {
            title: 'Pro',
            highlighted: true,
            //subheader: 'Bald verfügbar',
            price: {monthly: '75', yearly: "825"},
            description: [
                'Alles aus Starter',
                '(Auto-) Gruppenplanung',
                'Trainer-App',
                "Mitglieder Abgleich",
            ],
            buttonText: 'Get started',
            buttonVariant: 'contained',
        }
    ];

    return (
        <Container sx={pricingStyles.root} maxWidth={"xl"}>
            <Typography variant={"h1"} component={"h2"}>
                Preise
            </Typography>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    {/*<ToggleButtonGroup
                        color="primary"
                        value={paymentType}
                        exclusive
                        onChange={(evnt, value) => {
                            if (!value && value !== 0) {
                                return
                            }
                            setPaymentType(value)
                        }}
                        aria-label="Platform"
                    >
                        <ToggleButton value={0}>Monatlich</ToggleButton>
                        <ToggleButton value={1}>Jährlich</ToggleButton>
                    </ToggleButtonGroup> */}
                </Grid>
                <Grid item md={2}/>
                {tiers.map((tier) => (
                    // Enterprise card is full width at sm breakpoint
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === 'Enterprise' ? 12 : 6}
                        md={tier.title === 'Starter' ? 3 : 5}
                    >
                        <Card>
                            <CardHeader
                                title={tier.title}
                                subheader={tier.subheader}
                                titleTypographyProps={{align: 'center'}}
                                subheaderTypographyProps={{
                                    align: 'center',
                                }}
                                sx={{
                                    backgroundColor: (theme) =>
                                        tier.highlighted ? theme.palette.secondary.main :
                                            (theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700]),
                                }}
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 2,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        {paymentType === 0 ? tier.price.monthly : tier.price.yearly}€
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        /{paymentType === 0 ? "Monat" : "Jahr"}
                                    </Typography>
                                </Box>
                                <ul>
                                    {tier.description.map((line) => (
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <Grid item md={2}/>

            </Grid>
            <Typography align={"center"} color={"text.secondary"} pt={3}>
                Für kleine Clubs und Firmen bieten wir Spezialpreise. Zögere nicht uns zu fragen!
            </Typography>
            <Typography align={"center"} color={"text.secondary"}>
                Zu zahlen alle 3 Monate.
            </Typography>
        </Container>
    )

}

export default Pricing;
