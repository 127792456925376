import React from "react";
import {Box, Container, Grid, Stack, Typography, useTheme} from "@mui/material";
import CedricSquare from "../../assets/img/profilePictures/cedric/cedricSquare.webp";
import MemberCardSlim from "../MemberCardSlim/MemberCardSlim";

function Footer() {
    const theme = useTheme();

    const members = [
        {
            name: "Cedric",
            mail: "cedric.rische@cedsoft.de",
            imageTitle: "Image of Cedric",
            //telephone: "01525 2121831",
            image: CedricSquare,
            imageSquare: CedricSquare,
            teamFunction: "Für Fragen rund um Enroll",
            plannerLink: "https://app.cal.com/cedsoft-cedric.rische/enroll-presentation"
        }
    ]

    return (
        <>
            <Box sx={{
                width: "100%",
                paddingTop: "2rem",
                paddingBottom: "2rem",
                marginTop: "2rem",
                background: `linear-gradient(115deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`
            }}>
                <Container maxWidth={"xl"}>
                    <Grid container alignItems={"center"} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant={"h2"}>Haben wir dein Interesse geweckt?</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {members.map(member => (<MemberCardSlim name={member.name} mail={member.mail}
                                                                    imageTitle={member.imageTitle}
                                                                    telephone={member.telephone} image={member.image}
                                                                    teamFunction={member.teamFunction}
                                                                    plannerLink={member.plannerLink}
                                />)
                            )
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{
                width: "100%",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                backgroundColor: "primary.dark"
            }}>
                <Container maxWidth={"xl"}>
                    <Grid container>

                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: "primary.contrastText"}}>(c) 2024 Enroll Hub. All rights
                                reserved</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} display={"flex"} justifyContent={"right"}>
                            <Stack direction={"row"} spacing={1}>
                                <a target={"_blank"} rel="noreferrer" href={"https://enroll-hub.de/docs/"}
                                   style={{color: theme.palette.primary.contrastText, textDecoration: "none"}}>Hilfe</a>
                                <Typography>|</Typography>
                                <a href={"https://enroll-hub.de/privacy"} style={{
                                    color: theme.palette.primary.contrastText,
                                    textDecoration: "none"
                                }}>Datenschutz</a>
                                <Typography>|</Typography>
                                <a href={"https://enroll-hub.de/imprint"} style={{
                                    color: theme.palette.primary.contrastText,
                                    textDecoration: "none"
                                }}>Impressum</a>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Footer
